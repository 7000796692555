<template>
  <h2>{{ pages.current.title }}</h2>
  <div v-if="pages.current.content" v-html="contentHtml"/>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MarkdownHelper from '@/helpers/MarkdownHelper'

export default {
  props: ['slug'],
  computed: {
    ...mapState(['pages']),
    contentHtml () {
      return MarkdownHelper.renderMarkdown(this.pages.current.content)
    }
  },
  methods: {
    ...mapActions('pages', ['fetchPage']),
    getPage (slug) {
      this.fetchPage(slug)
        .catch(error => {
          this.$router.push({
            name: 'Message',
            params: {
              title: 'Fehler',
              content: error
            }
          })
        })
    }
  },
  created () {
    this.getPage(this.slug)
  },
  beforeRouteUpdate (to) {
    this.getPage(to.params.slug)
  }
}
</script>
