<template>
  <Navigation scope="project" :allow-reduced="!!this.projects.current.description"/>
  <div v-if="projects.current.description" v-html="renderMarkdown(projects.current.description)" class="sidebar__info"/>
</template>

<script>
import { mapState } from 'vuex'
import Navigation from '@/components/Navigation'
import MarkdownHelper from '@/helpers/MarkdownHelper'

export default {
  components: { Navigation },
  computed: {
    ...mapState(['projects'])
  },
  methods: {
    renderMarkdown (markdown) {
      return MarkdownHelper.renderMarkdown(markdown)
    }
  }
}
</script>
