export default {
  namespaced: true,
  state: {
    navigation: {
      open: false
    }
  },
  mutations: {
    SET_VIEW_NAVIGATION (state, open) {
      state.navigation.open = open
    }
  },
  actions: {
    toggleNavigation ({
      commit,
      state
    }) {
      commit('SET_VIEW_NAVIGATION', !state.navigation.open)
    },
    closeNavigation ({ commit }) {
      commit('SET_VIEW_NAVIGATION', false)
    }
  }
}
