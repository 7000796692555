<template>
  <h2>Aktuelle Termine</h2>
  <div v-for="event of events.list" :key="event._id" class="events__event">
    <h3>
      <router-link v-if="event.project" :to="{ name: 'Project', params: { slug: event.project._id } }">
        {{ event.title }}
      </router-link>
      <template v-else>
        {{ event.title }}
      </template>
    </h3>
    <template v-if="event.image">
      <router-link v-if="event.project" :to="{ name: 'Project', params: { slug: event.project._id } }">
        <img :src="getImageUrl(event.image.path)" alt="" class="events__image">
      </router-link>
      <img v-else :src="getImageUrl(event.image.path)" alt="" class="events__image">
    </template>
    <div class="events__info">
      <span v-if="event.type">{{ event.type }}: </span>
      <span>{{ getPrettyDate(event.date) }}</span>
    </div>
    <div v-if="event.begin && event.end" class="events__info">
      <span>{{ getPrettyDate(event.begin) }} bis {{ getPrettyDate(event.end) }}</span>
    </div>
    <div v-if="event.location" class="events__info">
      <span>{{ event.location }}</span>
    </div>
    <div v-if="event.description" v-html="renderMarkdown(event.description)" class="events__description"/>
  </div>
  <div class="events__pagination">
    <div class="events__previous">
      <router-link
        v-if="previousPage"
        :to="{ name: 'Events', params: { page: previousPage }}"
      >
        &lt;
      </router-link>
    </div>
    <div>Seite {{ currentPage }}</div>
    <div class="events__next">
      <router-link
        v-if="nextPage"
        :to="{ name: 'Events', params: { page: nextPage }}"
      >
        &gt;
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ImageHelper from '@/helpers/ImageHelper'
import DateHelper from '@/helpers/DateHelper'
import MarkdownHelper from '@/helpers/MarkdownHelper'

export default {
  props: ['page'],
  computed: {
    ...mapState(['events']),
    currentPage () {
      return this.getCurrentPage(this.page)
    },
    previousPage () {
      if (this.currentPage === 1) return null
      return (this.currentPage - 1)
    },
    nextPage () {
      if ((this.currentPage * this.events.limit) >= this.events.total) return null
      return (this.currentPage + 1)
    }
  },
  methods: {
    ...mapActions('events', ['fetchEvents']),
    getCurrentPage (page) {
      const pageInt = parseInt(page)
      if (!Number.isInteger(pageInt) || (pageInt < 1)) return 1
      return pageInt
    },
    getEvents (page) {
      this.fetchEvents(page)
        .catch(error => {
          this.$router.push({
            name: 'Message',
            params: {
              title: 'Fehler',
              content: error
            }
          })
        })
    },
    getImageUrl (src) {
      return ImageHelper.getImageUrl(src)
    },
    getPrettyDate (date) {
      return DateHelper.getPrettyDate(date)
    },
    renderMarkdown (markdown) {
      return MarkdownHelper.renderMarkdown(markdown)
    }
  },
  created () {
    this.getEvents(this.currentPage)
  },
  beforeRouteUpdate (to) {
    const currentPage = this.getCurrentPage(to.params.page)
    this.getEvents(currentPage)
  }
}
</script>

<style lang="scss">
.events {
  &__event {
    margin-top: 4em;

    &:first-of-type {
      margin-top: 2em;
    }
  }

  &__image {
    margin-top: 0.5em;
    max-width: 100%;
  }

  &__info {
    margin-top: 0.5em;
  }

  &__description img {
    max-width: 100%;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 4em;
  }

  &__previous,
  &__next {
    flex: 1;

    a {
      border-radius: 0.5em;
      border: 1px solid #aaaaaa;
      color: #aaaaaa;
      margin: 0 1em;
      padding: 0.5em 1em;
      text-decoration: none;

      &:hover {
        border-color: #cccccc;
        color: #cccccc;
      }
    }
  }

  &__previous {
    text-align: right;
  }
}
</style>
