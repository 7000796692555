<template>
  <h2 v-if="projects.current.title">{{ projects.current.title }}, {{ projects.current.year }}</h2>
  <div v-else class="loading-indicator"></div>
  <div
    v-if="projects.current.content"
    v-html="renderMarkdown(projects.current.content)"
    class="project__content project__content--above"
  />
  <div v-if="projects.current.gallery">
    <ul class="project__gallery">
      <li
        v-for="(image, i) of projects.current.gallery"
        :key="i"
        @mouseenter="addHoverClass"
        @mouseleave="removeHoverClass"
        class="project__image"
      >
        <a :href="getImageUrl(image.path)" @click.prevent="showLightbox(i)">
          <img :src="getThumbnailUrl(image.path)" :alt="image.meta.title">
        </a>
        <div v-if="image.meta.copyright" class="project__image-copyright">&copy; {{ image.meta.copyright }}</div>
        <div v-if="(image.meta.title || image.meta.description)" class="project__image-tooltip">
          <div v-if="image.meta.title" class="project__image-title">{{ image.meta.title }}</div>
          <div
            v-if="image.meta.description"
            v-html="renderMarkdown(image.meta.description)"
            class="project__image-description"
          />
        </div>
      </li>
    </ul>
    <VueEasyLightbox
      scrollDisabled
      moveDisabled
      :visible="lightboxVisible"
      :imgs="getGalleryImages"
      :index="imageIndex"
      @hide="hideLightbox"
    >
      <template v-slot:toolbar/>
    </VueEasyLightbox>
  </div>
  <div
    v-if="projects.current.content"
    v-html="renderMarkdown(projects.current.content)"
    class="project__content project__content--below"
  />
  <div v-if="projects.current.description" class="project__info">
    <h3 class="project__info-headline">Informationen</h3>
    <div v-html="renderMarkdown(projects.current.description)"/>
  </div>
  <div v-if="press.project.length" class="project__press">
    <h3 class="project__press-headline" id="presse">Presse</h3>
    <Accordion
      v-for="pressArticle of press.project"
      :key="pressArticle._id"
      :title="pressArticle.title"
    >
      <div v-if="pressArticle.content" v-html="renderMarkdown(pressArticle.content)"/>
      <div class="project__press-info">
        <span v-if="pressArticle.author">{{ pressArticle.author }}, </span>
        <span v-if="pressArticle.media">{{ pressArticle.media }}, </span>
        {{ getPrettyDate(pressArticle.date) }}
      </div>
    </Accordion>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MarkdownHelper from '@/helpers/MarkdownHelper'
import ImageHelper from '@/helpers/ImageHelper'
import DateHelper from '@/helpers/DateHelper'
import Accordion from '@/components/Accordion'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  data () {
    return {
      lightboxVisible: false,
      imageIndex: 0
    }
  },
  components: {
    VueEasyLightbox,
    Accordion
  },
  props: ['slug'],
  computed: {
    ...mapState(['projects', 'press']),
    getGalleryImages () {
      return ImageHelper.getGalleryImages(this.projects.current.gallery)
    }
  },
  methods: {
    ...mapActions('projects', ['fetchProject']),
    ...mapActions('press', ['fetchPressByProjectId']),
    getProject (slug) {
      this.fetchProject(slug)
        .then(response => {
          if (slug !== response.slug) {
            this.$router.replace({
              name: 'Project',
              params: { slug: response.slug }
            })
          }
          return this.fetchPressByProjectId(response._id)
        })
        .catch(error => {
          this.$router.push({
            name: 'Message',
            params: {
              title: 'Fehler',
              content: error
            }
          })
        })
    },
    renderMarkdown (markdown) {
      return MarkdownHelper.renderMarkdown(markdown)
    },
    getThumbnailUrl (src) {
      return ImageHelper.getThumbnailUrl(src, 150)
    },
    getImageUrl (src) {
      return ImageHelper.getImageUrl(src)
    },
    addHoverClass (event) {
      if (event.target.classList.length) {
        const className = event.target.classList[0] + '--hover'
        event.target.classList.add(className)
      }
    },
    removeHoverClass (event) {
      if (event.target.classList.length) {
        const className = event.target.classList[0] + '--hover'
        event.target.classList.remove(className)
      }
    },
    showLightbox (imageIndex) {
      this.imageIndex = imageIndex
      this.lightboxVisible = true
    },
    hideLightbox () {
      this.lightboxVisible = false
    },
    getPrettyDate (date) {
      return DateHelper.getPrettyDate(date)
    }
  },
  created () {
    this.getProject(this.slug)
  },
  beforeRouteUpdate (to) {
    if (this.projects.current.title_slug !== to.params.slug) this.getProject(to.params.slug)
  }
}
</script>

<style lang="scss">
.project {

  &__content {
    &--above {
      @media (max-width: $breakpoint - 1) {
        display: none;
      }
    }

    &--below {
      @media (min-width: $breakpoint) {
        display: none;
      }
    }
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
    margin-top: 2em;
    padding: 0;
  }

  &__image {
    max-width: 150px;
    padding: 0;
    position: relative;
  }

  &__image-copyright {
    color: #666;
    font-size: 11px;
    position: absolute;
    z-index: 10;
  }

  &__image-tooltip {
    background-color: $element-background-color-active;
    display: none;
    font-size: $font-size-small;
    padding: calc(#{$space} / 2);
    position: absolute;
    z-index: 100;

    .project__image--hover & {
      display: block;
    }
  }

  &__image-title {
    font-weight: bold;
  }

  &__info {
    margin-top: 2em;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__press-headline {
    margin: 2em 0;
  }

  &__press-info {
    font-style: italic;
    margin-top: 1em;
  }
}
</style>
