import { createStore } from 'vuex'
import config from './modules/config.js'
import view from './modules/view.js'
import events from './modules/events.js'
import projects from './modules/projects.js'
import press from './modules/press.js'
import pages from './modules/pages.js'

export default createStore({
  modules: {
    config,
    view,
    events,
    projects,
    press,
    pages
  }
})
