import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Events from '@/views/Events'
import Projects from '@/views/Projects'
import Project from '@/views/Project'
import Press from '@/views/Press'
import Page from '@/views/Page'
import Message from '@/views/Message'
import store from '@/store'

const routes = [{
  name: 'Home',
  path: '/',
  component: Home
}, {
  name: 'Events',
  path: '/termine/:page?',
  component: Events,
  props: true
}, {
  name: 'Projects',
  path: '/projekte/:slug',
  component: Projects,
  props: true
}, {
  name: 'Project',
  path: '/projekt/:slug',
  component: Project,
  props: true,
  meta: { sidebar: 'Project' }
}, {
  name: 'Press',
  path: '/presse',
  component: Press
}, {
  name: 'Page',
  path: '/seite/:slug',
  component: Page,
  props: true
}, {
  name: 'Message',
  path: '/meldung',
  component: Message,
  props: true
}, {
  name: 'NotFound',
  path: '/:catchAll(.*)',
  component: Message,
  props: {
    title: 'Fehler',
    content: '404'
  }
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

router.afterEach(() => {
  store.dispatch('view/closeNavigation')
})

export default router
