import DataService from '@/services/DataService.js'

export default {
  namespaced: true,
  state: {
    categories: [],
    category: {},
    list: [],
    current: {}
  },
  mutations: {
    SET_PROJECTS_CATEGORIES (state, categories) {
      state.categories = categories
    },
    SET_PROJECTS_CATEGORY (state, category) {
      state.category = category
    },
    SET_PROJECTS_LIST (state, projects) {
      state.list = projects
    },
    SET_PROJECTS_CURRENT (state, project) {
      state.current = project
    },
    CLEAR_PROJECTS_CATEGORY_AND_LIST (state) {
      state.category = {}
      state.list = []
    }
  },
  actions: {
    fetchCategories ({ commit }) {
      return DataService.getCategories()
        .then(response => {
          commit('SET_PROJECTS_CATEGORIES', response.data.entries)
        })
        .catch(error => {
          throw error
        })
    }, // TODO: Refactor?
    fetchProjectsByCategorySlug ({
      commit,
      state
    }, slug) {
      const category = state.categories.find(category => category.title_slug === slug)
      if (!category) {
        commit('CLEAR_PROJECTS_CATEGORY_AND_LIST')
        return DataService.getCategory(slug)
          .then(response => {
            if (response.data.entries.length < 1) throw new Error('404')
            commit('SET_PROJECTS_CATEGORY', response.data.entries[0])
            return DataService.getProjectsByCategoryId(response.data.entries[0]._id)
          })
          .then(response => {
            commit('SET_PROJECTS_LIST', response.data.entries)
          })
          .catch(error => {
            throw error
          })
      } else if (state.category._id !== category._id) {
        commit('CLEAR_PROJECTS_CATEGORY_AND_LIST')
        return DataService.getProjectsByCategoryId(category._id)
          .then(response => {
            commit('SET_PROJECTS_CATEGORY', category)
            commit('SET_PROJECTS_LIST', response.data.entries)
          })
          .catch(error => {
            throw error
          })
      }
    },
    fetchProject ({
      commit,
      state
    }, slug) {
      const project = state.list.find(project => project.title_slug === slug)
      if (project) {
        commit('SET_PROJECTS_CURRENT', project)
        return {
          _id: project._id,
          slug: slug
        }
      } else {
        return DataService.getProject(slug)
          .then(response => {
            if (response.data.entries.length < 1) throw new Error('404')
            commit('SET_PROJECTS_CURRENT', response.data.entries[0])
            return {
              _id: response.data.entries[0]._id,
              slug: response.data.entries[0].title_slug
            }
          })
          .catch(error => {
            throw error
          })
      }
    }
  }
}
