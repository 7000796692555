<template>
  <aside class="sidebar">
    <component :is="sidebar"/>
  </aside>
</template>

<script>
import MainSidebar from '@/components/MainSidebar'
import ProjectSidebar from '@/components/ProjectSidebar'

export default {
  components: {
    MainSidebar,
    ProjectSidebar
  },
  computed: {
    sidebar () {
      return (this.$route.meta.sidebar || 'Main') + 'Sidebar'
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  flex: 0 0 225px;

  &__info {
    margin: $space;

    @media (max-width: $breakpoint - 1) {
      display: none;
    }
  }
}
</style>
