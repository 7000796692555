import DataService from '@/services/DataService.js'

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
    limit: 5
  },
  mutations: {
    CLEAR_EVENTS_LIST (state) {
      state.list = []
    },
    SET_EVENTS_LIST (state, {
      events,
      total
    }) {
      state.list = events
      state.total = total
    }
  },
  actions: {
    fetchEvents ({
      commit,
      state
    }, page) {
      commit('CLEAR_EVENTS_LIST')
      return DataService.getEvents(state.limit, page)
        .then(response => {
          commit('SET_EVENTS_LIST', {
            events: response.data.entries,
            total: response.data.total
          })
        })
        .catch(error => {
          throw error
        })
    }
  }
}
