<template>
  <template v-if="config.current.title">
    <h1>
      <router-link :to="{ name: 'Home'}">{{ config.current.title }}</router-link>
    </h1>
    <div class="layout">
      <Sidebar/>
      <main>
        <router-view/>
      </main>
    </div>
  </template>
  <div v-else class="loading-indicator"></div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Sidebar from '@/components/Sidebar'

export default {
  components: {
    Sidebar
  },
  computed: {
    ...mapState(['config'])
  },
  methods: {
    ...mapActions('config', ['fetchConfig']),
    ...mapActions('projects', ['fetchCategories']),
    ...mapActions('press', ['fetchPressOverview']),
    ...mapActions('pages', ['fetchPages'])
  },
  created () {
    this.fetchConfig()
      .catch(error => {
        this.$router.push({
          name: 'Message',
          params: {
            title: 'Fehler',
            content: error
          }
        })
      })
    this.fetchCategories()
      .catch(error => {
        this.$router.push({
          name: 'Message',
          params: {
            title: 'Fehler',
            content: error
          }
        })
      })
    this.fetchPages()
      .catch(error => {
        this.$router.push({
          name: 'Message',
          params: {
            title: 'Fehler',
            content: error
          }
        })
      })
    this.fetchPressOverview()
      .catch(error => {
        this.$router.push({
          name: 'Message',
          params: {
            title: 'Fehler',
            content: error
          }
        })
      })
  }
}
</script>

<style lang="scss">
.loading-indicator {
  margin-bottom: 50px;

  &::after {
    animation: loading-indicator 1.2s infinite;
    display: inline-block;
    border-color: #fff transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    content: " ";
    height: 25px;
    width: 25px;
  }
}

@keyframes loading-indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// TODO: Refactor
// Legacy
/* Allgemein */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Dokument */
body {
  background-color: $background-color;
  color: $text-color;
  font-family: Verdana, serif;
  font-size: $font-size;
  margin: 0 auto 2em;
  max-width: 1024px;
  padding: 1em;
}

/* Links */
a:link,
a:hover,
a:active,
a:visited {
  text-decoration: underline;
  color: $text-color;

  h1 &,
  .navigation & {
    text-decoration: none;
  }
}

/* Überschiften */
h1 {
  font-size: 35px;
  font-weight: normal;
  letter-spacing: 22px;
  margin: 0.5em 0 1em;
}

h2 {
  margin: 1em 0 0;
  padding: 0;
}

@media (min-width: $breakpoint) {
  h2 {
    margin-top: 0;
  }
}

/* Absätze */
p {
  line-height: 1.5em;
  margin: 1em 0 0;
  padding: 0;
}

// Lists
ul,
ol {
  padding: 0.5em 2em;

  li {
    padding: 0.5em 0;
  }
}

// Quotes
blockquote {
  border-left: 3px solid $text-color;
  padding-left: 1em;
  margin: 1em 0;
}

// Layout
.layout {
  @media (min-width: $breakpoint) {
    display: flex;
    gap: 60px;
  }
}
</style>
