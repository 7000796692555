import MarkdownHelper from '@/helpers/MarkdownHelper'

export default {
  getImageUrl (src, max, mode) {
    if (!max) max = 1920
    if (!mode) mode = 'bestFit'
    return process.env.VUE_APP_API_BASE_URL +
      '/cockpit/image' +
      '?token=26862aa2abb346ea2170dadafdf2bb' +
      '&src=' + src +
      '&m=' + mode +
      '&w=' + max +
      '&h=' + max +
      '&o=1'
  },
  getGalleryImages (gallery, max) {
    const imageUrls = []
    for (const image of gallery) {
      let title = image.meta.title
      if (image.meta.description) {
        const div = document.createElement('div')
        div.innerHTML = MarkdownHelper.renderMarkdown(image.meta.description)
        title += ' / ' + div.textContent.trim().replaceAll('\n', ' / ')
      }
      imageUrls.push({
        title: title,
        src: this.getImageUrl(image.path, max)
      })
    }
    return imageUrls
  },
  getThumbnailUrl (src, max) {
    return this.getImageUrl(src, max, 'thumbnail')
  }
}
