<template>
  <div class="accordion" :class="{ 'accordion--open': open }">
    <button class="accordion__toggle" @click="toggleAccordion">
      <span class="accordion__title">{{ title }}</span>
      <span class="accordion__indicator">&#9660;</span>
    </button>
    <div class="accordion__content">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      open: false
    }
  },
  props: [
    'title'
  ],
  computed: {},
  methods: {
    toggleAccordion () {
      this.open = !this.open
    }
  }
}
</script>

<style lang="scss">
.accordion {

  & + & {
    margin-top: $space;
  }

  &__toggle {
    background-color: $element-background-color;
    border-bottom: 0;
    border-left: 1px solid $element-background-color;
    border-right: 1px solid $element-background-color;
    border-top: 1px solid $element-background-color;
    color: $text-color;
    cursor: pointer;
    font-size: $font-size;
    font-weight: bold;
    padding: $space ($space * 2) $space $space;
    position: relative;
    text-align: left;
    width: 100%;

    .accordion--open & {
      background-color: $element-background-color-active;
      border-color: $element-background-color-active;
    }
  }

  &__indicator {
    font-weight: normal;
    position: absolute;
    right: $space;
    top: ($space + 1px);

    .accordion--open & {
      transform: rotate(180deg);
    }
  }

  &__content {
    border-bottom: 2px solid $element-background-color-active;
    border-left: 2px solid $element-background-color-active;
    border-right: 2px solid $element-background-color-active;
    height: 0;
    overflow: hidden;

    .accordion--open & {
      height: auto;
      padding: 0 $space $space;
    }
  }
}
</style>
