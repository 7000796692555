<template>
  <img
    v-if="config.current.startImage.path"
    :src="getImageUrl(this.config.current.startImage.path)"
    alt=""
    class="home__teaser"
  />
</template>

<script>
import { mapState } from 'vuex'
import ImageHelper from '@/helpers/ImageHelper'

export default {
  computed: {
    ...mapState(['config'])
  },
  methods: {
    getImageUrl (src) {
      return ImageHelper.getImageUrl(src)
    }
  }
}
</script>

<style lang="scss">
.home {

  &__teaser {

    max-width: 100%;

    @media (max-width: $breakpoint - 1) {
      margin-top: 2em;
    }
  }
}
</style>
