<template>
  <nav
    class="navigation"
    :class="{ 'navigation--open': view.navigation.open, 'navigation--reduced': isReduced }"
  >
    <button type="button" @click="toggleNavigation" class="navigation__toggle">Menü</button>
    <ul class="navigation__list">
      <li class="navigation__item">
        <router-link :to="{ name: 'Home' }" class="navigation__link">Start</router-link>
      </li>
      <li class="navigation__item">
        <router-link :to="{ name: 'Events' }" class="navigation__link">Aktuelle Termine</router-link>
      </li>
      <li
        v-for="category of projects.categories"
        :key="category._id"
        class="navigation__item"
        :class="{ 'navigation__item--is-parent': isCurrentCategory(category._id) }"
      >
        <router-link :to="{ name: 'Projects', params: { slug: category.title_slug } }" class="navigation__link">
          {{ category.title }}
        </router-link>
      </li>
      <li class="navigation__item">
        <router-link :to="{ name: 'Press' }" class="navigation__link">Presse</router-link>
      </li>
      <li v-for="page of pages.list" :key="page._id" class="navigation__item">
        <router-link :to="{ name: 'Page', params: { slug: page.title_slug } } " class="navigation__link">
          {{ page.title }}
        </router-link>
      </li>
      <li class="navigation__item">
        <ul class="navigation__social">
          <li><a href="mailto:info@jakob-knapp.de" title="E-Mail"><img src="/assets/icons/mail.svg" alt="E-Mail"></a></li>
          <li><a href="https://www.instagram.com/jakob_knapp_de/" target="_blank" title="Instagram"><img src="/assets/icons/instagram.svg" alt="Instagram"></a></li>
          <li><a href="https://www.facebook.com/jakob.knapp.37" target="_blank" title="Facebook"><img src="/assets/icons/facebook.svg" alt="Facebook"></a></li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['scope', 'allowReduced'],
  computed: {
    ...mapState([
      'view',
      'projects',
      'pages'
    ]),
    isReduced () {
      return (this.isScope('project') && this.allowReduced)
    }
  },
  methods: {
    ...mapActions('view', ['toggleNavigation']),
    isScope (scope) {
      return (this.scope === scope)
    },
    isCurrentCategory (categoryId) {
      return (
        (this.isScope('project')) &&
        (this.projects.current.category) &&
        (this.projects.current.category._id === categoryId))
    }
  }
}
</script>

<style lang="scss">
.navigation {
  &__toggle {
    background-color: $element-background-color;
    border-radius: 0;
    border: 0;
    color: $text-color;
    cursor: pointer;
    font-family: Verdana, sans-serif;
    font-size: $font-size;
    margin-bottom: 4px;
    padding: $space;
    text-align: left;
    width: 100%;

    @media (min-width: $breakpoint) {
      display: none;
    }

    .navigation--reduced & {
      display: block;
    }

    .navigation--open & {
      font-weight: bold;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 2em;
    padding: 0;

    & + ul {
      padding-top: 0.5em;
    }

    @media (max-width: $breakpoint - 1) {
      display: none;
    }

    .navigation--reduced & {
      display: none;
    }

    .navigation--open & {
      display: block;
    }
  }

  &__item {
    background-color: $element-background-color;
    margin: 0;
    padding: 0;

    & + & {
      margin-top: 4px;
    }

    &--is-parent {
      background-color: $element-background-color-active;
    }
  }

  &__link {
    display: block;
    padding: $space;
    text-decoration: none;

    &.router-link-active {
      font-weight: bold;
    }
  }

  &__social {
    justify-content: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }

    a {
      display: inline-block;
      padding: 10px;
    }

    img {
      vertical-align: middle;
    }
  }
}
</style>
