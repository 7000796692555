<template>
  <h2 v-if="projects.category.title">{{ projects.category.title }}</h2>
  <ul v-if="projects.list.length" class="projects">
    <li v-for="project of projects.list" :key="project._id" class="projects__project">
      <router-link :to="{ name: 'Project', params: { slug: project.title_slug }}">
        <img v-if="project.image" :src="getImageThumbnailUrl(project.image.path)" :alt="project.title">
        <br>{{ project.title }}<span v-if="project.year">, {{ project.year }}</span>
      </router-link>
    </li>
  </ul>
  <div v-else class="loading-indicator"></div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ImageHelper from '@/helpers/ImageHelper'

export default {
  props: ['slug'],
  computed: {
    ...mapState(['projects'])
  },
  methods: {
    ...mapActions('projects', ['fetchProjectsByCategorySlug']),
    getProjects (slug) {
      this.fetchProjectsByCategorySlug(slug)
        .catch(error => {
          this.$router.push({
            name: 'Message',
            params: {
              title: 'Fehler',
              content: error
            }
          })
        })
    },
    getImageThumbnailUrl (src) {
      return ImageHelper.getThumbnailUrl(src, 200)
    }
  },
  beforeMount () {
    this.getProjects(this.slug)
  },
  beforeRouteUpdate (to) {
    this.getProjects(to.params.slug)
  }
}
</script>

<style lang="scss">
.projects {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  list-style: none;
  margin-top: 2em;
  padding: 0;

  &__project {
    max-width: 200px;
    padding: 0;
  }
}
</style>
