import DataService from '@/services/DataService.js'

export default {
  namespaced: true,
  state: {
    overview: [],
    list: [],
    project: []
  },
  mutations: {
    SET_PRESS_OVERVIEW (state, overview) {
      state.overview = overview
    }, // TODO: Implement caching
    ADD_PRESS_LIST (state, press) {
      for (const pressArticle of press) state.list.push(pressArticle)
    },
    SET_PRESS_PROJECT (state, press) {
      state.project = press
    },
    CLEAR_PRESS_PROJECT (state) {
      state.project = []
    }
  },
  actions: {
    fetchPressOverview ({ commit }) {
      return DataService.getPressOverview()
        .then(response => {
          commit('SET_PRESS_OVERVIEW', response.data.entries)
        })
        .catch(error => {
          throw error
        })
    },
    fetchPressByProjectId ({
      commit,
      state
    }, projectId) {
      commit('CLEAR_PRESS_PROJECT')
      const press = state.overview.filter(press => !!press.project.find(project => project._id === projectId))
      if (!press.length) return
      const ids = []
      for (const pressArticle of press) {
        ids.push(pressArticle._id)
      }
      return DataService.getPressByIds(ids)
        .then(response => {
          commit('SET_PRESS_PROJECT', response.data.entries)
        })
        .catch(error => {
          throw error
        })
    }
  }
}
