<template>
  <h2>Presse</h2>
  <div v-for="pressArticle of press.overview" :key="pressArticle._id" class="press__article">
    <div>
      <span v-for="(project, i) of pressArticle.project" :key="project._id">
        <template v-if="i !== 0">, </template>
        <router-link :to="{ name: 'Project', params: { slug: project._id } }">
          {{ project.display }}
        </router-link>
      </span>
    </div>
    <h3 class="press__article-headline">{{ pressArticle.title }}</h3>
    <div class="press__info">
      <span v-if="pressArticle.author">{{ pressArticle.author }}, </span>
      <span v-if="pressArticle.media">{{ pressArticle.media }}, </span>
      {{ getPrettyDate(pressArticle.date) }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DateHelper from '@/helpers/DateHelper'

export default {
  computed: {
    ...mapState(['press'])
  },
  methods: {
    getPrettyDate (date) {
      return DateHelper.getPrettyDate(date)
    }
  }
}
</script>

<style lang="scss">
.press {
  &__article {
    margin-top: 4em;

    &:first-of-type {
      margin-top: 2em;
    }
  }

  &__article-headline {
    margin-top: 0.5em;
  }

  &__info {
    font-style: italic;
    margin-top: 0.5em;
  }
}
</style>
