<template>
  <h2 v-if="title">{{ title }}</h2>
  <p>{{ content }}</p>
</template>

<script>
export default {
  props: [
    'title',
    'content'
  ]
}
</script>
