import DataService from '@/services/DataService.js'

export default {
  namespaced: true,
  state: {
    list: [],
    current: {}
  },
  mutations: {
    SET_PAGES_LIST (state, pages) {
      state.list = pages
    },
    SET_PAGES_CURRENT (state, page) {
      state.current = page
    }
  },
  actions: {
    fetchPages ({ commit }) {
      return DataService.getPages()
        .then(response => {
          commit('SET_PAGES_LIST', response.data.entries)
        })
        .catch(error => {
          throw error
        })
    },
    fetchPage ({
      commit,
      state
    }, slug) {
      const page = state.list.find(page => page.title_slug === slug)
      if (page) {
        commit('SET_PAGES_CURRENT', page)
      } else {
        return DataService.getPage(slug)
          .then(response => {
            if (response.data.entries.length < 1) throw new Error('404')
            commit('SET_PAGES_CURRENT', response.data.entries[0])
          })
          .catch(error => {
            throw error
          })
      }
    }
  }
}
