import axios from 'axios'

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const getSkip = (limit, page) => {
  return limit * (page - 1)
}

export default {
  getConfig () {
    return client.post('/singletons/get/config')
  },
  getEvents (limit, page) {
    return client.post('/collections/get/events', {
      filter: { inMenu: true },
      sort: { date: -1 },
      limit: limit,
      skip: getSkip(limit, page)
    })
  },
  getCategories () {
    return client.post('/collections/get/categories', {
      filter: { inMenu: true },
      sort: { _o: 1 }
    })
  },
  getCategory (slug) {
    return client.post('/collections/get/categories', {
      filter: { title_slug: slug }
    })
  },
  getProjectsByCategoryId (categoryId) {
    return client.post('/collections/get/projects', {
      filter: {
        category: categoryId,
        inMenu: true
      },
      sort: { year: -1 }
    })
  },
  getProject (slug) {
    return client.post('/collections/get/projects', {
      filter: {
        $or: [{ title_slug: slug }, { _id: slug }]
      }
    })
  },
  getPages () {
    return client.post('/collections/get/pages', {
      filter: { inMenu: true },
      sort: { _o: 1 }
    })
  },
  getPage (slug) {
    return client.post('/collections/get/pages', {
      filter: { title_slug: slug }
    })
  },
  getPressOverview () {
    return client.post('/collections/get/press', {
      filter: { inMenu: true },
      fields: {
        project: 1,
        title: 1,
        author: 1,
        media: 1,
        date: 1
      },
      sort: { date: -1 }
    })
  },
  getPressByIds (ids) {
    return client.post('/collections/get/press', {
      filter: { _id: { $in: ids } },
      sort: { date: -1 }
    })
  }
}
