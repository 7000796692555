import DataService from '@/services/DataService.js'

export default {
  namespaced: true,
  state: {
    current: {}
  },
  mutations: {
    SET_CONFIG_CURRENT (state, config) {
      state.current = config
    }
  },
  actions: {
    fetchConfig ({ commit }) {
      return DataService.getConfig()
        .then(response => {
          commit('SET_CONFIG_CURRENT', response.data)
        })
        .catch(error => {
          throw error
        })
    }
  }
}
